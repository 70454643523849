import React, { useState } from 'react';
import Layout from './Layout'; // Ensure the correct path to Layout
import './TrainerRecruiting.css'; // Create a CSS file for styling
import api from './axiosConfig'; // Assuming you have an axiosConfig for API calls

const TrainerRecruiting: React.FC = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        language: 'English & German' // Default value
    });

    const [error, setError] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validatePhoneNumber = (phone: string) => {
        // Validate phone: no letters, length between 4-30 characters
        const phoneRegex = /^[^a-zA-Z]{4,30}$/;
        return phoneRegex.test(phone);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const trimmedData = {
            firstName: formData.firstName.trim(),
            lastName: formData.lastName.trim(),
            phone: formData.phone.trim(),
            language: formData.language
        };

        const { firstName, lastName, phone, language } = trimmedData;

        if (!firstName || !lastName || !phone) {
            setError('All fields are required.');
            return;
        }

        if (!validatePhoneNumber(phone)) {
            setError('Please enter a valid phone number');
            return;
        }

        setError('');

        try {
            const response = await api.post('/trainer-application', {
                firstName: firstName,
                lastName: lastName,
                phone: phone,
                language: language,
                source: 'website'
            });

            console.log('Application submitted:', response.data);
            setIsSubmitted(true);
        } catch (error) {
            const err = error as any;
            console.error('Error submitting application:', err);
            setError('Failed to submit application. Please try again.');
        }
    };

    return (
        <Layout backgroundColor="var(--color-light-blue)">
            <div className="trainer-recruiting-container">
                <div className="left-container">
                    <h1>Join the future of group strength training. Become a LIFTED trainer!</h1>
                    <div className="content-section">
                        <h3>Why Trainers Thrive at LIFTED</h3>
                        <div>
                            <br />
                            <p><strong>Making a difference:</strong> We’re here to shake things up. Join us at the forefront as we break stereotypes and make strength training accessible to everyone.</p>
                            <br />
                            <p><strong>Defining strength:</strong> Join the first group strength training program that truly embodies what strength is all about.</p>
                            <br />
                            <p><strong>Being yourself:</strong> We believe in being real. At LIFTED, we value honesty, authenticity, people who don’t take themselves too seriously and the joy that comes from staying true to yourself and others.</p>
                            <br />
                            <p><strong>Working with ease:</strong> Flexible hours, freelance contract, access to cutting-edge equipment, and competitive pay are just a few ways we ensure you can thrive.</p>
                            <br />
                            <p><strong>Joining an awesome team:</strong> We’re fresh and new—but our roots run deep in Berlin’s fitness community. Whether we’ve crossed paths before or not, now is the best time to join our mission.</p>
                        </div>
                    </div>
                    <div className="content-section">
                        <h3>How to become a LIFTED trainer</h3>
                        <br />
                        <p><strong>Step 1: Let’s connect</strong><br />
                            Leave your contact details below, and we’ll be in touch to schedule a chat. This is our chance to get to know each other, answer your questions, and share everything you need to feel confident about the next steps.</p>
                        <br />
                        <p><strong>Step 2: Show us your potential</strong><br />
                            Lead one of our trial workouts to demonstrate your skills and energy. We’re looking for passion, potential, and team fit—not perfection on day one.</p>
                        <br />
                        <p><strong>Step 3: Become a LIFTED trainer</strong><br />
                            If it’s a match, we’ll provide comprehensive training and onboarding to set you up for success. Once ready, you’ll step into your role as an official LIFTED trainer, fully equipped to make an impact.</p>
                    </div>

                    <div className="form-container">
                        {isSubmitted ? (
                            <h3 style={{ color: "var(--color-neon-blue)" }}>Thank you for your interest! We will be in touch soon.</h3>
                        ) : (
                            <form className="form" onSubmit={handleSubmit}>
                                <h3>Apply here</h3>
                                <input type="text" name="firstName" placeholder="First Name" className="form-input" value={formData.firstName} onChange={handleChange} />
                                <input type="text" name="lastName" placeholder="Last Name" className="form-input" value={formData.lastName} onChange={handleChange} />
                                <input type="text" name="phone" placeholder="Phone" className="form-input" value={formData.phone} onChange={handleChange} />
                                <select name="language" className="form-input" value={formData.language} onChange={handleChange}>
                                    <option value="English & German">English & German</option>
                                    <option value="English only">English only</option>
                                    <option value="German only">German only</option>
                                </select>
                                {error && <p className="error-text">{error}</p>}
                                <button type="submit" className="special">Submit</button>
                            </form>
                        )}
                    </div>
                </div>
                <div className="right-container">
                    <div className="border-box">
                        <img className="border-img" src="https://res.cloudinary.com/dxznerft3/image/upload/v1733220718/homepage_pictures/become-a-trainer.jpg" alt="Become a Trainer" />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default TrainerRecruiting; 