import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css'; // Ensure this CSS file exists for styling
import Layout from './Layout'; // Ensure the correct path to Layout

const HomePage: React.FC = () => {
    const navigate = useNavigate();

    const handleBookWorkout = () => {
        navigate('/workouts');
    };

    return (
        <Layout backgroundColor="transparent" paddingTop="0px" paddingBottom="0px"> 
            <div className="homepage-container">
                <div className="flex-vertical gap-s">
                    <img src="/logo-text.svg" alt="Logo" className="text-logo inverted" />
                    <h3>Strength Training for <span style={{ fontFamily: 'PPEditorialNew' }}>Everyone</span></h3>
                </div>
                <button className="special" onClick={handleBookWorkout}>Book Workout</button>
            </div>
        </Layout>
    );
};

export default HomePage;
